<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Call request</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>

        <div class="card">
          <div class="card-body ">
            <div v-if="hiddenCustomerNameAddressCallRequestData"
                 class="d-flex justify-content-center align-items-center" style="min-height: 60vh;">
              <div class="customer-find-section">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Email / Phone Number* *</label>
                      <input v-model="emailOrPhone" type="text" class="form-control"
                             placeholder="Enter email or phone"/>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label style="visibility:hidden ;">Next Button</label>
                      <button :disabled="!isCustomerFindValidateActive"
                              @click="findExistingCustomerHandler"
                              class="btn btn-primary d-block">Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!hiddenCustomerNameAddressCallRequestData">
              <div class="pb-2">
                <h5 class="text-muted">Customer Information </h5>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>FIRST NAME *</label>
                      <input v-model="postUser.firstName" type="text" class="form-control"
                             placeholder="Enter first name"/>
                    </div>
                  </div>
                  <div class="col-md-3 ">
                    <div class="form-group">
                      <label>LAST NAME *</label>
                      <input v-model="postUser.lastName" type="text" class="form-control"
                             placeholder="Enter last name"/>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>PHONE NUMBER</label>
                      <input :disabled="validateMobile(emailOrPhone)" v-model="postUser.phone" type="text"
                             class="form-control" placeholder="Enter phone no"/>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>EMAIL ADDRESS </label>
                      <input :disabled="validateEmail(emailOrPhone)" v-model="postUser.email" type="text"
                             class="form-control text-lowercase" placeholder="Enter email"/>
                    </div>
                  </div>

                </div>
              </div>
              <div class="row ">
                <div class="col-12 col-md-12">
                  <div class="form-group">
                    <label>Address</label>
                    <vue-google-autocomplete :enable-geolocation="true" ref="address" :country="['au']" id="map"
                                             class="form-control" placeholder="Please type full address."
                                             v-on:placechanged="googleMapApiResponseData"/>
                  </div>
                </div>
              </div>
              <div class="pb-3" v-if="this.street || this.suburb || this.selectedState?.name || this.post_code">
                <p class="text-danger">Please modify the following address fields, if there anything wrong.</p>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Street Address</label>
                      <input v-model="street" type="text" class="form-control" placeholder="Enter street address"/>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-6 ">
                    <div class="form-group">
                      <label>Suburbs/ Territory</label>
                      <input v-model="suburb" type="text" class="form-control" placeholder="Enter suburbs/ territory"/>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-6">
                    <div class="form-group">
                      <label>State</label>
                      <VueMultiselect v-model="selectedState" class="" :options="state" :close-on-select="true"
                                      placeholder="Select state" label="name" track-by="value" :show-labels="false"
                                      :allow-empty="false"/>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-6">
                    <div class="form-group">
                      <label>Post Code</label>
                      <input v-model="post_code" type="text" class="form-control" placeholder="Enter code"/>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-6">
                    <div class="form-group">
                      <label>Country</label>
                      <input v-model="country" type="text" class="form-control" readonly/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <label>Select priority</label>
                  <VueMultiselect v-model="selectedPriority" :options="priorityOptions" :close-on-select="true"
                                  placeholder="Select priority" label="name" track-by="value" :show-labels="false"
                                  :allow-empty="false"/>
                </div>

                <div class="col-md-3" v-if="taggingEnableStatus">
                  <label>Tagging</label>
                  <VueMultiselect v-model="selectedTagFor" :options="tagForOptions" :close-on-select="true"
                                  placeholder="Select Tag For" label="name" track-by="name" :show-labels="false"
                                  :allow-empty="false"/>
                </div>

                <div class="col-md-3"
                     v-if="taggingEnableStatus && (selectedTagFor.value === 0 || selectedTagFor.value === 1)">
                  <label>Tag Reference</label>
                  <VueMultiselect v-model="selectedTagReference" :options="tagReferenceOptions" :close-on-select="true"
                                  placeholder="Select Tag For" label="name" track-by="name" :show-labels="false"
                                  :allow-empty="false"/>
                </div>

                <div class="col-md-12">
                  <label>Reason for calling *</label>
                  <textarea v-model="message" class="form-control" name="" id="" rows="10"></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <div class="d-flex justify-content-end">
                    <button :disabled="!isCalculateButtonActive" @click="callRequestHandler()" type="button"
                            class="btn btn-primary update-todo px-4">Create call request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="" data-toggle="modal" data-target="#costDetailsModal">
      </div> -->
    </template>
  </AppLayout>
</template>

<script>
//internal components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
// 3rd party packages
import VueMultiselect from 'vue-multiselect';
// import {
//     DatePicker
// } from 'v-calendar';
import VueGoogleAutocomplete from 'vue-google-autocomplete';

// core packages
import {
  mapActions,
  mapGetters
} from 'vuex';

export default {
  components: {
    AppLayout,
    VueMultiselect,

    VueGoogleAutocomplete,
    // DatePicker

  },
  name: "CallRequestCreate",
  mixins: [ShowToastMessage, Loader, Authorization],
  data() {
    return {
      emailOrPhone: '',
      hiddenCustomerNameAddressCallRequestData: true,
      taggingEnableStatus: false,
      tagReferenceOptions: [
        {
          value: null,
          name: 'No Reference'
        }
      ],
      selectedState: {},
      selectedPriority: {},
      selectedTagFor: {
        value: null,
        name: 'No One'
      },
      selectedTagReference: {},

      postUser: {
        id: null,
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      },
      postCustomer: {
        id: null
      },
      addressId: null,
      street: '',
      suburb: '',
      post_code: '',
      country: '',

      message: '',

      errors: {},

    }
  },
  computed: {
    ...mapGetters({
      settingDefaultCustomerRole: 'appSettings/settingDefaultCustomerRole',
      user: 'appUsers/user',
      customer: 'appCustomers/customer',
      address: 'appAddresses/address',

      previousRoute: 'previousRoute',

      authUser: 'appAuthLogin/authUser',

      // call request
      callRequest: 'appCallRequests/callRequest',
    }),

    isCalculateButtonActive() {
      if (this.selectedTagFor.value !== null && this.selectedTagReference.value === null) {
        return  false;
      }
      return (this.street && this.suburb && this.state && this.post_code && ((this.postUser.email) || (this.postUser.phone)) && this.postUser.firstName && this.postUser.lastName && this.selectedPriority.value !== undefined && this.message);
    },

    isCustomerFindValidateActive() {
      return ((this.validateEmail()) || (this.validateMobile()));
    },

    state() {
      return [... this.$store.getters['appSettings/settingDefaultState'].value]
    },
    redirectRouteName() {
      return this.can('service-view-any') ? 'appServiceList' : 'appServiceCreate';
    },

    tagForOptions() {
      return [
        {
          value: null,
          name: 'No One',
        },
        {
          value: 0,
          name: 'Appointment',
        },
        {
          value: 1,
          name: 'Order',
        },
      ];
    },

    priorityOptions() {
      return [
        {
          name: 'Low',
          value: 0
        },
        {
          name: 'Medium',
          value: 1
        },
        {
          name: 'High',
          value: 2
        }
      ];
    },
  },
  watch: {
    selectedTagFor(selectedTagFor) {
      this.resetSelectedTagReference();

      if (selectedTagFor.value === 0) {
        this.tagReferenceOptions = this.user?.customer?.appointments.map((appointment) => {
          return {
            value: appointment.reference,
            name: appointment.reference,
          };
        });
      }
      if (selectedTagFor.value === 1) {
        this.tagReferenceOptions = this.user?.orders.map((order) => {
          return {
            value: order.order_no,
            name: order.order_no,
          };
        });
      }
    }
  },
  methods: {
    ...mapActions({
      // customer and user
      userFind: 'appUsers/userFind',
      postUserHandler: 'appUsers/postUser',
      resetUser: 'appUsers/resetUser',
      postAddress: 'appAddresses/postAddress',
      postCustomerHandler: 'appCustomers/postCustomer',

      // call request
      postCallRequest: 'appCallRequests/postCallRequest',
      postCallRequestNotifyEmail: 'appCallRequests/postCallRequestNotifyEmail',

      // call request message
      postCallRequestMessage: 'appCallRequestMessages/postCallRequestMessage',


      // setting
      getSettings: 'appSettings/getSettings',

    }),

    // validations
    validateEmail() {
      //eslint-disable-next-line
      let emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return emailFormat.test(this.emailOrPhone);
    },

    validateMobile() {
      // eslint-disable-next-line
      let mobileFormat = /^(02|04)[0-9]{8}$/;
      return mobileFormat.test(this.emailOrPhone);
    },

    async getSettingList() {
      this.loader(true);
      const response = await this.getSettings({
        type: ['default'],
        key: ['default_state', 'default_customer_role'],
      });
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response.message);
      }
    },

    googleMapApiResponseData: function (googleMapApiResponseData) {

      let streetNumber = googleMapApiResponseData.street_number !== undefined ? googleMapApiResponseData.street_number : '';
      let streetName = googleMapApiResponseData.route !== undefined ? googleMapApiResponseData.route : '';
      let suburbs = googleMapApiResponseData.locality !== undefined ? googleMapApiResponseData.locality : '';
      let state = googleMapApiResponseData.administrative_area_level_1 !== undefined ? googleMapApiResponseData.administrative_area_level_1 : '';
      let postCode = googleMapApiResponseData.postal_code !== undefined ? googleMapApiResponseData.postal_code : '';
      let country = googleMapApiResponseData.country !== undefined ? googleMapApiResponseData.country : '';

      this.street = streetNumber ? `${streetNumber} ${streetName}` : streetName;
      this.suburb = suburbs;
      this.state = state.toLowerCase();
      this.post_code = postCode;
      this.country = country;

      if (state) {
        let addressStateIndex = this.state.findIndex(item => item.value === state.toLowerCase());
        if (addressStateIndex !== -1) {
          let addressState = this.state[addressStateIndex];
          this.selectedState.value = addressState.value;
          this.selectedState.name = addressState.name;
        }
      }

    },

    async findUser(data) {
      await this.resetUser();

      this.loader(true);
      const existingUserResponse = await this.userFind(data);
      this.loader(false);

      if (existingUserResponse.message) {
        this.showToastMessage(existingUserResponse);
      }

      if (existingUserResponse.status === 200) {

        let userId = this.user?.id ?? null;
        let customerId = this.user?.customer?.id ?? null;

        this.taggingEnableStatus = this.user?.orders?.length > 0 || this.user?.customer?.appointments?.length > 0;

        if (userId) {
          this.postUser.id = this.user.id;
          this.postUser.firstName = this.user.first_name;
          this.postUser.lastName = this.user.last_name;
          this.postUser.email = this.user.email;
          this.postUser.phone = this.user.phone_number;

        } else {

          this.postUser.id = null;
          if (this.validateEmail()) {
            this.postUser.email = this.emailOrPhone;
            return ((this.validateEmail()) || (this.validateMobile()));
          }
          if (this.validateMobile()) {
            this.postUser.phone = this.emailOrPhone;
          }
        }

        if (customerId) {

          this.postCustomer.id = this.user.customer.id;
          if (this.user.customer?.address?.id) {
            this.street = this.user.customer.address.street;
            const existingState = this.state.find(item => item.value.toLowerCase().trim() === this.user.customer.address.state.toLowerCase().trim());

            if (existingState) {
              this.selectedState = existingState;
            }
            this.suburb = this.user.customer.address.suburb;
            this.addressId = this.user.customer.address.id;
            this.country = this.user.customer.address.country;
            this.post_code = this.user.customer.address.post_code;
          } else {
            this.addressId = null;
          }

        } else {
          this.postCustomer.id = null;
        }

      }

    },

    async findExistingCustomerHandler() {

      await this.findUser({
        search_query: this.emailOrPhone,
        with_relation: ['customer.address', 'customer.appointments', 'orders'],
      });
      this.hiddenCustomerNameAddressCallRequestData = false;
    },

    resetSelectedTagReference() {
      this.selectedTagReference = {
        value: null,
        name: "No One",
      };
    },
    async createUser() {
      const postUserData = {
        role_id: this.settingDefaultCustomerRole.value.value !== undefined ? parseInt(this.settingDefaultCustomerRole.value.value) : '',
        first_name: this.postUser.firstName,
        last_name: this.postUser.lastName,
      };
      if (this.postUser.email) {
        postUserData.email = this.postUser.email
      }
      if (this.postUser.phone) {
        postUserData.phone_number = this.postUser.phone
      }
      this.loader(true);
      const response = await this.postUserHandler(postUserData);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      if (response.status === 200 || response.status === 201) {

        this.postUser.id = this.user.id;
        return true;
      }
      return false;
    },
    async createSingleAddress() {

      const postAddressData = {
        user_id: this.postUser.id,
        street: this.street,
        suburb: this.suburb,
        state: this.selectedState.value.toLowerCase(),
        post_code: this.post_code,
        country: this.country,
      };
      this.loader(true);
      const response = await this.postAddress(postAddressData);
      this.loader(false);
      if (response.status === 200 || response.status === 201) {
        this.addressId = this.address.id;
        return true;

      }

      if (response.message) {
        this.showToastMessage(response);
      }

      return false;
    },

    async createSingleCustomer() {
      this.loader(true);
      const postCustomerData = {
        address_id: this.address.id,
        user_id: this.postUser.id,
        type: 0,
        newsletter_subscription: 1,
        status: 1,
      };
      const response = await this.postCustomerHandler(postCustomerData);
      this.loader(false);
      if (response.status === 201) {
        this.postCustomer.id = this.customer.id;
        return true;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
      return false;
    },
    async createCallRequest() {

      const postCallRequestData = {

        user_id: this.postUser.id,
        priority: this.selectedPriority.value,
        tag_for: this.selectedTagFor.value !== null  && this.selectedTagReference.value !== null ?  this.selectedTagFor.value : null,
        tag_reference: this.selectedTagFor.value !== null && this.selectedTagReference.value !== null ? this.selectedTagReference.value : null,
      };

      const response = await this.postCallRequest(postCallRequestData);
      this.loader(false);
      if (response.status === 201) {

        return true;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
      return false;
    },
    async createCallRequestMessage() {
      this.loader(true);
      const postCallRequestMessageData = {

        creator_id: this.authUser.id,
        call_request_id: this.callRequest.id,
        message: this.message,

      };
      const response = await this.postCallRequestMessage(postCallRequestMessageData);
      this.loader(false);
      if (response.status === 201) {

        return true;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
      return false;
    },
    callRequestCreationEmailNotify() {
      const dataObj = {
        id: this.callRequest.id,
        data: {
          user_id: this.postUser.id,
          priority: this.selectedPriority.value,

        }

      };
      this.postCallRequestNotifyEmail(dataObj);
    },
    async callRequestHandler() {

      if (this.postUser.id === null) {
        const isUserCreatedSuccessfully = await this.createUser();
        if (!isUserCreatedSuccessfully) {
          return;
        }
      }
      if (this.postCustomer.id === null) {
        const isSingleAddressCreatedSuccessfully = await this.createSingleAddress();
        if (!isSingleAddressCreatedSuccessfully) {
          return;
        }
      }
      if (this.postCustomer.id === null) {
        const isSingleCustomerCreatedSuccessfully = await this.createSingleCustomer();
        if (!isSingleCustomerCreatedSuccessfully) {
          return;
        }
      }

      // create call request
      const isCallRequestCreatedSuccessfully = await this.createCallRequest();
      if (!isCallRequestCreatedSuccessfully) {
        return;
      }

      // create call request message
      const isCallRequestMessageCreatedSuccessfully = await this.createCallRequestMessage();
      if (!isCallRequestMessageCreatedSuccessfully) {
        return;
      }
      this.callRequestCreationEmailNotify();

      await this.$router.push({
        name: 'appCallRequestList',
      });

      this.showToastMessage({
        type: 'success',
        message: 'Call request created successfully ❤️'
      });

    },

  },

  async mounted() {
    await this.getSettingList();
  },
}
</script>

<style scoped>
.customer-find-section {
  width: 50%;
}

@media only screen and (max-width: 900px) {
  .customer-find-section {
    width: 80%;
  }
}

p {
  padding-bottom: 0;
  margin-bottom: 0;
}

/*.multiselect__tags:focus-within {*/

/*  !* border:1px solid red !important; *!*/
/*  border-color: #5A8DEE;*/

/*}*/

/*.multiselect__tags {*/
/*  min-height: 0;*/
/*  max-height: 38px !important;*/
/*  border-color: #DFE3E7;*/
/*}*/
</style>
